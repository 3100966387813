<template>
  <div role="group" class="form-group form-row">
    <label v-if="label || array >= 1" :for="name" class="col-form-label" :class="settings.classes.label">{{ array >= 1 ? '' : $t(label) }}</label>
    <div class="row no-gutters" :class="settings.classes.input">
      <!--CInput ref="input" v-model="state" v-bind="$attrs" v-on="listeners" :class="{ 'col-sm-12': !helper, 'col-sm-6': !!helper }" /-->
      <div :class="{ 'col-sm-12': !helper, 'col-sm-5': !!helper }">
        <div class="input-group">
          <datetime ref="input" class="form-control p-0" :class="{ 'col-sm-12': !!helper, 'col-sm-5': !helper }" :input-class="{ 'form-control': true, 'is-invalid': !isValid }" :title="$t(label)" :auto="true" v-model="state" :type="type || 'datetime'" :name="name" :placeholder="$t(c_placeholder)" :phrases="{ ok: $t('Ok'), cancel: $t('Cancel') }" v-bind="$attrs" v-on="listeners" @input="onInput($event)" @change="onChange($event)" />
          <div v-if="$attrs.reset" class="input-group-append">
            <button type="button" role="button" class="input-group-text" @click="onInput('')"><CIcon name="cil-x" /></button>
          </div>
          <div v-if="array >= 0" class="input-group-append">
            <button type="button" role="button" class="input-group-text" @click="remove(array)"><CIcon name="cil-x" /></button>
          </div>
        </div>
      </div>
      <Label v-if="helper" class="ml-3 col-sm-5 control-helper">{{ $t(helper) }}</Label>
      <template v-if="!isValid">
        <!--div v-if="!errors.length" class="invalid-feedback">{{ $(`The :attribute field is invalid`).replace(':attribute', $t(label || '')) }}</div-->
        <div v-for="(error, index) in errors" :key="index" class="invalid-feedback" style="display: block;">{{ error.message }}</div>
      </template>
    </div>
  </div>
</template>

<script>
const CONFIG = { classes: { input: 'col-sm-9', label: 'col-sm-3' } }

export default {
  name: 'AInputDatetime',
  inheritAttrs: true,
  watch: {
    value(val) {
      this.state = val
    }
  },
  props: {
    type: String,
    value: {
      type: [String, Number]
    },
    label: {
      type: String
    },
    horizontal: {
      type: [Boolean, Object],
      default: false
    },
    helper: {
      type: String
    },
    placeholder: {
      type: String
    },
    array: {
      type: Number,
      default: -1
    },
    remove: {
      type: Function,
      default: () => false
    },

    isValid: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array
    }
  },
  computed: {
    name() {
      return this.$attrs.name || this.$attrs.id || this.$vnode.data.model.expression.split('.').pop()
    },
    name_clean() {
      return this.name.split('.')[0]
    },
    listeners() {
      const { input, change, ...listeners } = this.$listeners
      return listeners
      /*return {
        ...this.$listeners,
        input: event => this.$emit('input', event.value)
        //change: event => this.$emit('change', event),
        //update: event => this.$emit('update:value', event)
      }*/
    }
  },
  data() {
    return {
      state: this.value,
      settings: { ...CONFIG },
      c_placeholder: this.$attrs.placeholder || this.placeholder || this.label
    }
  },
  created() {
    if (typeof this.horizontal === 'object') this.settings.classes = { ...this.settings.classes, ...this.horizontal }
  },
  methods: {
    onInput(value) {
      this.state = this.type === 'time' ? (value || '').substr(11, 5) : value
      this.$emit('input', this.state)
      /*if (this.lazy === true) {
        return
      }*/

      clearTimeout(this.syncTimeout)
      this.syncTimeout = setTimeout(
        () => {
          this.$emit('update:value', value)
        } /*,
        this.lazy !== false ? this.lazy : 0*/
      )
    },
    onChange(value) {
      this.state = this.type === 'time' ? (value || '').substr(11, 5) : value

      console.log(value)
      //this.$emit('input', value)
      //this.$emit('change', value)
      //this.$emit('update:value', value)
    },
    onClose(event) {
      this.$emit('close', event)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style';

.control-helper {
  color: $input-placeholder-color;
  font-size: 0.9em;
  padding-top: 7px;
}

.invalid-feedback {
  display: block;
}
</style>
